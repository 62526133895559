import {
  faCircleXmark,
  faMagnifyingGlass,
  faRotateLeft,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Circle } from "rc-progress";
import { Doughnut, Line } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Dropdown,
  FloatingLabel,
  FormControl,
  Row,
} from "react-bootstrap";
import { DatePicker, Select } from "antd";
import TotalSum from "../statsComponents/TotalSum";
import StaffStats from "../statsComponents/StaffStats";
import { useDispatch, useSelector } from "react-redux";
import {
  calculateBellmanStats,
  calculateIndividualTipStats,
  calculateMaidStats,
  calculateRefundedTransactionStats,
  calculateTeamTipStats,
  calculateTotalCommissionCharged,
  calculateTotalStripeFee,
  calculateTotalSubcharged,
  calculateTotalSum,
  calculateValetStats,
  getMonthlyData,
  getUnverifiedTeamTips,
  getVerifiedTeamTips,
  getWeeklyData,
} from "../controllers/stats";
import Loader from "../components/Loader";
import { getAllStaff, getAllValetTeams } from "../redux/Actions/maidAction";
import { listUsers } from "../redux/Actions/userAction";
import { getAllPaymentReports } from "../redux/Actions/paymentAction";

Chart.register(...registerables);
const Stats = () => {
  const { RangePicker } = DatePicker;
  const { Option } = Select;
  const [query, setQuery] = useState("");
  const [errorMessage, setErrorMessage] = useState(false);
  const [fliterApplied, setFilterApplied] = useState(false);
  const [reset, setReset] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [dateRange, setDateRange] = useState([null, null]);
  const [monthlyData, setMonthlyData] = useState({});
  const [weeklyData, setWeeklyData] = useState({});
  const [doughnutData, setDoughnutData] = useState(null);
  const [dailyData, setDailyData] = useState({});
  const [filteredTransactions, setFilteredTransactions] = useState([]);
  const [staff, setStaff] = useState([]);
  const [valetTeams, setValetTeams] = useState([]);
  const [filteredStaff, setFilteredStaff] = useState([]);
  const [filteredTeams, setFilteredTeams] = useState([]);

  const { loading: transactionsLoading, allPayments: allPyaments } =
    useSelector((state) => state.paymentReports);
  const {
    staff: staffData,
    loading: staffLoading,
    error: staffError,
  } = useSelector((state) => state.getAllStaff);
  const {
    valetTeams: valetTeamsData,
    loading: valetTeamsLoading,
    error: valetTeamsError,
  } = useSelector((state) => state.getAllValetTeams);

  const {
    loading: usersLoading,
    error: usersError,
    users,
  } = useSelector((state) => state.userList);
  const clients =
    users && users.length > 0
      ? users.filter((user) => !user.isSuperAdmin && !user.isPartner)
      : [];

  const dispatch = useDispatch();

  const handleInputChange = (e) => {
    const input = e.target.value;
    setSelectedUserId(null);
    setQuery(input);
    setErrorMessage(false);

    if (input.trim().length > 0) {
      const matches = clients.filter((user) =>
        user.companyName.toLowerCase().includes(input.toLowerCase())
      );
      setFilteredUsers(matches);
      setShowDropdown(matches.length > 0);
    } else {
      setFilteredUsers([]);
      setShowDropdown(false);
    }
  };
  const handleDateRangeChange = (dates) => {
    if (dates === null || dates[0] === null || dates[1] === null) {
      setErrorMessage(false);
      setDateRange([null, null]);
    } else {
      setDateRange(dates);
    }
  };

  const handleSelectUser = (user) => {
    setQuery(user.companyName);
    setSelectedUserId(user._id);
    setShowDropdown(false);
  };

  useEffect(() => {
    dispatch(getAllPaymentReports());
    dispatch(getAllValetTeams());
    dispatch(listUsers());
  }, []);

  useEffect(() => {
    if (staffData?.length > 0) {
      setStaff(staffData);
    }
    if (valetTeamsData?.length > 0) {
      setValetTeams(valetTeamsData);
    }
  }, [staffData, valetTeamsData]);

  useEffect(() => {
    // Calculate and set the monthly data
    if (allPyaments && allPyaments.length > 0) {
      const monthlyChartData = {
        labels: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ],
        datasets: [
          {
            label: "Transactions",
            data: getMonthlyData(
              fliterApplied ? filteredTransactions : allPyaments
            ),
            backgroundColor: "rgba(75,192,192,0.4)", // Teal
            borderColor: "rgba(75,192,192,1)", // Teal
            borderWidth: 2,
            fill: true,
          },
        ],
      };
      setMonthlyData(monthlyChartData);

      // Calculate and set the weekly data
      let targetMonth;
      let targetYear;
      if (startDate && endDate) {
        const start = new Date(startDate);
        targetMonth = start.getMonth();
        targetYear = start.getFullYear();
      } else {
        targetMonth = new Date().getMonth();
        targetYear = new Date().getFullYear();
      }
      const weeklyDataCounts = getWeeklyData(
        fliterApplied ? filteredTransactions : allPyaments,
        targetMonth,
        targetYear
      );
      const weeklyChartData = {
        labels: Array.from({ length: 31 }, (_, i) => i + 1),
        datasets: [
          {
            label: "Week 1",
            data: weeklyDataCounts.datasets[0].data,
            borderColor: "rgba(75, 192, 192, 1)", // Teal
            backgroundColor: "rgba(75, 192, 192, 0.2)", // Teal (transparent)
            borderWidth: 2,
            fill: true,
          },
          {
            label: "Week 2",
            data: weeklyDataCounts.datasets[1].data,
            borderColor: "rgba(153, 102, 255, 1)", // Purple
            backgroundColor: "rgba(153, 102, 255, 0.2)", // Purple (transparent)
            borderWidth: 2,
            fill: true,
          },
          {
            label: "Week 3",
            data: weeklyDataCounts.datasets[2].data,
            borderColor: "rgba(255, 159, 64, 1)", // Orange
            backgroundColor: "rgba(255, 159, 64, 0.2)", // Orange (transparent)
            borderWidth: 2,
            fill: true,
          },
          {
            label: "Week 4",
            data: weeklyDataCounts.datasets[3].data,
            borderColor: "rgba(255, 99, 132, 1)", // Red
            backgroundColor: "rgba(255, 99, 132, 0.2)", // Red (transparent)
            borderWidth: 2,
            fill: true,
          },
        ],
      };

      setWeeklyData(weeklyChartData);
    }
    // Calculate and set the doughnut chart data for valet team tips
    if (valetTeams) {
      const verifiedTeamTipsCount = getVerifiedTeamTips(valetTeams);
      const unverifiedTeamTipsCount = getUnverifiedTeamTips(valetTeams);

      const doughnutChartData = {
        labels: ["Verified Tips", "Unverified Tips"],
        datasets: [
          {
            data: [verifiedTeamTipsCount, unverifiedTeamTipsCount],
            backgroundColor: ["#36A2EB", "#FF6384"],
            hoverBackgroundColor: ["#36A2EB", "#FF6384"],
          },
        ],
      };
      setDoughnutData(doughnutChartData);
    }
  }, [fliterApplied, filteredTransactions, valetTeams, allPyaments]);

  useEffect(() => {
    setFilterApplied(false);
    let filteredTransactions = [...allPyaments];
    let filteredStaff = [...staffData];
    let filteredValetTeams = [...valetTeamsData];

    // Filter by selectedUserId if it's present
    if (selectedUserId) {
      setFilterApplied(true);
      console.log("Filtering by selected UserId");
      filteredTransactions = filteredTransactions.filter(
        (transaction) => transaction.clientId === selectedUserId
      );
      filteredStaff = filteredStaff.filter(
        (user) => user.hotel === selectedUserId
      );
      filteredValetTeams = filteredValetTeams.filter(
        (team) => team.hotel === selectedUserId
      );
    }

    // Filter by date range if both startDate and endDate are provided
    const [start, end] = dateRange;
    if (start && end) {
      setFilterApplied(true);
      console.log("Filtering by start & end date");
      const startDate = new Date(start);
      const endDate = new Date(end);

      filteredTransactions = filteredTransactions.filter((transaction) => {
        const transactionDate = new Date(transaction.createdAt);
        return transactionDate >= startDate && transactionDate <= endDate;
      });
      filteredStaff = filteredStaff.filter((user) => {
        const userCreatedAt = new Date(user.createdAt);
        return userCreatedAt >= startDate && userCreatedAt <= endDate;
      });
      filteredValetTeams = filteredValetTeams.filter((team) => {
        const teamCreatedAt = new Date(team.createdAt);
        return teamCreatedAt >= startDate && teamCreatedAt <= endDate;
      });
    }

    setFilteredTransactions(filteredTransactions);
    setStaff(filteredStaff);
    setValetTeams(filteredValetTeams);
  }, [dateRange, selectedUserId]);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
      },
      tooltip: {
        enabled: true,
        backgroundColor: "rgba(0, 0, 0, 0.8)",
        titleColor: "#ffffff",
        bodyColor: "#e0e0e0",
        padding: 10,
        displayColors: false,
        callbacks: {
          title: (tooltipItems) => `📅 ${tooltipItems[0].label}`,
          label: (tooltipItem) => `🔥 Transactions: ${tooltipItem.raw}`,
        },
      },
    },
    // responsive: true,
    // maintainAspectRatio: false,
  };
  const donoughtOptions = {
    plugins: {
      tooltip: {
        enabled: true,
        padding: 12,
        titleFont: {
          size: 16, // Bigger title font
          weight: "bold",
        },
        bodyFont: {
          size: 14, // Bigger body font
        },
        boxPadding: 10, // Adds padding around dataset color box
      },
    },
  };
  return (
    <>
      <Row className="m-0 py-4">
        <Col>
          {/* ------------------------------------------------ FILTERS ---------------------------------------------- */}
          <Row className="align-items-center justify-content-center mb-5 p-2 rounded-2 bg-light">
            <Col xs="auto" className="">
              <h3 className="mb-0 text-primary fs-5 ">Perfomance Overview</h3>
            </Col>
            <Col xs={3} className="ms-auto">
              <RangePicker
                className="rounded-2 py-1 border-0 w-100"
                onChange={handleDateRangeChange}
              />
            </Col>
            <Col xs={2}>
              <div className="position-relative">
                <FormControl
                  className="rounded-2 py-1 border-0 text-muted"
                  type="text"
                  value={query}
                  onChange={handleInputChange}
                  placeholder="Client name"
                />
                <span
                  className="position-absolute top-50 end-0 translate-middle-y me-2 text-secondary"
                  style={{ cursor: "pointer" }}
                >
                  {query || showDropdown ? (
                    <FontAwesomeIcon
                      icon={faCircleXmark}
                      size="sm"
                      onClick={() => {
                        setQuery("");
                        setSelectedUserId(null);
                        setShowDropdown(false);
                        // setFilterApplied(false);
                      }}
                    />
                  ) : (
                    <FontAwesomeIcon icon={faMagnifyingGlass} size="sm" />
                  )}
                </span>
              </div>
              {showDropdown && (
                <Dropdown show>
                  <Dropdown.Menu
                    className="rounded-4 mt-1"
                    style={{ width: "100%" }}
                  >
                    {filteredUsers.map((user, index) => (
                      <Dropdown.Item
                        key={index}
                        onClick={() => handleSelectUser(user)}
                      >
                        {user.companyName}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </Col>
          </Row>
          {/* ------------------------------------------------ TABS ----------------------------------------------- */}
          {/* ===================================== TRANSACTION RECORDS =============================== */}
          {transactionsLoading ? (
            <Row>
              <Loader />
              <h6 className="text-center my-1 fw-normal">
                Loading Transaction Data
              </h6>
            </Row>
          ) : (
            <Row className="border border-0 px-5 pt-3 my-5">
              <Col xs={4}>
                <h5 className=" fw-normal">Total Transactions</h5>
                <span className="fw-normal text-primary fs-2 ms-5 ">
                  {(fliterApplied ? filteredTransactions : allPyaments).length}
                </span>
              </Col>
              <Col xs={4}>
                <h5 className=" fw-normal">Total Money Processed</h5>
                <span className="fw-normal text-success fs-2 ms-5 ">
                  $
                  {calculateTotalSum(
                    fliterApplied ? filteredTransactions : allPyaments
                  )}
                </span>
              </Col>
              <Col xs={4} className="ms-auto">
                <Row>
                  {/* ------------------------------------------ INDIVIDUAL TIPS RATIO ------------------------------------ */}
                  <Col xs={4}>
                    <Row className="position-relative circle-container mx-auto">
                      <Circle
                        percent={
                          calculateIndividualTipStats(
                            fliterApplied ? filteredTransactions : allPyaments
                          ).percentageOfIndividualTips
                        }
                        strokeWidth={10}
                        trailWidth={8}
                        strokeColor={"#06BF9C"}
                      />
                      <div className="circle-text">
                        <span className="text-success">
                          {
                            calculateIndividualTipStats(
                              fliterApplied ? filteredTransactions : allPyaments
                            ).percentageOfIndividualTips
                          }
                          %
                        </span>
                      </div>
                    </Row>
                    <Row>
                      <span className="text-success text-center">
                        Direct tips{" "}
                        {
                          calculateIndividualTipStats(
                            fliterApplied ? filteredTransactions : allPyaments
                          ).numberOfIndividualTipTransactions
                        }
                      </span>
                    </Row>
                  </Col>
                  {/* ------------------------------------------ VALE POOL TIPS RATIO ------------------------------------ */}
                  <Col xs={4}>
                    <Row className="position-relative circle-container mx-auto">
                      <Circle
                        percent={
                          calculateTeamTipStats(
                            fliterApplied ? filteredTransactions : allPyaments
                          ).percentageOfTeamTips
                        }
                        strokeWidth={10}
                        trailWidth={8}
                        strokeColor={"#06BF9C"}
                      />
                      <div className="circle-text">
                        <span className="text-success">
                          {
                            calculateTeamTipStats(
                              fliterApplied ? filteredTransactions : allPyaments
                            ).percentageOfTeamTips
                          }
                          %
                        </span>
                      </div>
                    </Row>
                    <Row>
                      <span className="text-success text-center">
                        Pool tips{" "}
                        {
                          calculateTeamTipStats(
                            fliterApplied ? filteredTransactions : allPyaments
                          ).numberOfTeamTipTransactions
                        }
                      </span>
                    </Row>
                  </Col>
                  {/* ------------------------------------------ REFUNDED RATIO ------------------------------------ */}
                  <Col xs={4}>
                    <Row className="position-relative circle-container mx-auto">
                      <Circle
                        percent={
                          calculateRefundedTransactionStats(
                            fliterApplied ? filteredTransactions : allPyaments
                          ).percentageOfRefundedTransactions
                        }
                        strokeWidth={10}
                        trailWidth={8}
                        strokeColor={"#3498db"}
                      />
                      <div className="circle-text">
                        <span className="text-info">
                          {
                            calculateRefundedTransactionStats(
                              fliterApplied ? filteredTransactions : allPyaments
                            ).percentageOfRefundedTransactions
                          }
                          %
                        </span>
                      </div>
                    </Row>
                    <Row>
                      <span className="text-info text-center">
                        Refunded{" "}
                        {
                          calculateRefundedTransactionStats(
                            fliterApplied ? filteredTransactions : allPyaments
                          ).numberOfRefundedTransactions
                        }
                      </span>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
          {/* ===================================== PAYMENT BREAKDOWN ================================= */}
          <Row className="my-4 px-4 justify-content-between">
            <TotalSum
              title={"Total Subcharged"}
              amount={calculateTotalSubcharged(
                fliterApplied ? filteredTransactions : allPyaments
              )}
              percentage={4.5}
            />
            <TotalSum
              title={"Total Stripe Fee Paid"}
              amount={calculateTotalStripeFee(
                fliterApplied ? filteredTransactions : allPyaments
              )}
              percentage={2.5}
            />
            <TotalSum
              title={"Total Commission Charged"}
              amount={calculateTotalCommissionCharged(
                fliterApplied ? filteredTransactions : allPyaments
              )}
            />
            <TotalSum
              title={"Dock $1 Revenue"}
              amount={
                (fliterApplied ? filteredTransactions : allPyaments).length
              }
              percentage={4.5}
            />
          </Row>
          {/* ===================================== MONTHLY & WEELY GRAPHS ============================ */}
          <Row className="my-5 pt-4">
            <Col xs={6}>
              <h5 className="text-center mb-3">Monthly Tipping Activity</h5>
              {monthlyData?.datasets?.length > 0 && (
                <Line data={monthlyData} options={options} />
              )}
            </Col>
            <Col xs={6}>
              <h5 className="text-center mb-3">
                Weekly Tipping Activity{" "}
                <span className="fs-7 text-muted">(Current Month)</span>
              </h5>
              {weeklyData?.datasets?.length > 0 && (
                <Line data={weeklyData} options={options} />
              )}
            </Col>
          </Row>
          {/* ======================================== STAFF RECORDS ================================== */}
          {staffLoading ? (
            <Row>
              <Loader />
              <h6 className="text-center my-1 fw-normal">Loading Staff Data</h6>
            </Row>
          ) : (
            <Row className="border border-0 p-3 pt-5">
              <Col xs={5}>
                <h5 className=" fw-normal text-center">Total Staff</h5>
                <h2 className="fw-normal text-primary text-center">
                  {staff.length}
                </h2>
              </Col>
              <Col xs={4} className="ms-auto">
                <Row>
                  {/* ------------------------------------------ ATTENDANTS RATIO ------------------------------------ */}
                  <Col xs={4}>
                    <Row className="position-relative circle-container mx-auto">
                      <Circle
                        percent={calculateMaidStats(staff).percentageOfMaids}
                        strokeWidth={10}
                        trailWidth={8}
                        strokeColor={"#004172"}
                      />
                      <div className="circle-text">
                        <span className="text-primary">
                          {calculateMaidStats(staff).percentageOfMaids}%
                        </span>
                      </div>
                    </Row>
                    <Row>
                      <span className="text-primary text-center p-0">
                        Attendants {calculateMaidStats(staff).totalMaids}
                      </span>
                    </Row>
                  </Col>
                  {/* ------------------------------------------ VALET RATIO ------------------------------------ */}
                  <Col xs={4}>
                    <Row className="position-relative circle-container mx-auto">
                      <Circle
                        percent={calculateValetStats(staff).percentageOfValets}
                        strokeWidth={10}
                        trailWidth={8}
                        strokeColor={"#004172"}
                      />
                      <div className="circle-text">
                        <span className="text-primary">
                          {calculateValetStats(staff).percentageOfValets}%
                        </span>
                      </div>
                    </Row>
                    <Row>
                      <span className="text-primary text-center p-0">
                        Valets {calculateValetStats(staff).totalValets}
                      </span>
                    </Row>
                  </Col>
                  {/* ------------------------------------------ BELLMEN RATIO ------------------------------------ */}
                  <Col xs={4}>
                    <Row className="position-relative circle-container mx-auto">
                      <Circle
                        percent={
                          calculateBellmanStats(staff).percentageOfBellmen
                        }
                        strokeWidth={10}
                        trailWidth={8}
                        strokeColor={"#004172"}
                      />
                      <div className="circle-text">
                        <span className="text-primary">
                          {calculateBellmanStats(staff).percentageOfBellmen}%
                        </span>
                      </div>
                    </Row>
                    <Row>
                      <span className="text-primary text-center p-0">
                        Bellmen {calculateBellmanStats(staff).totalBellmen}
                      </span>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
          <Row className="pt-4 justify-content-between align-items-center">
            <Col xs={7}>
              <StaffStats
                title={"Attendants"}
                active={calculateMaidStats(staff).activeMaids}
                inActive={calculateMaidStats(staff).inactiveMaids}
              />

              <StaffStats
                title={"Valets"}
                active={calculateValetStats(staff).activeValets}
                inActive={calculateValetStats(staff).inactiveValets}
              />

              <StaffStats
                title={"Bell Staff"}
                active={calculateBellmanStats(staff).activeBellmen}
                inActive={calculateBellmanStats(staff).inactiveBellmen}
              />
            </Col>
            {valetTeamsLoading ? (
              <Col xs={4}>
                <Row>
                  <Loader />
                  <h6 className="text-center my-1 fw-normal">
                    Loading team tips data
                  </h6>
                </Row>
              </Col>
            ) : (
              <Col xs={4}>
                <h5 className="text-center">Valet Pool Tips</h5>
                {doughnutData && (
                  <Doughnut data={doughnutData} options={donoughtOptions} />
                )}
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Stats;
