import axios from "axios";
import CryptoJS from "crypto-js";

const SECRET_KEY = "12345678901234567890123456789012";

const decryptResponse = (encryptedData) => {
  if (!encryptedData || !encryptedData.includes(":")) {
    console.warn("❌ Invalid Encrypted Data Format:", encryptedData);
    return null;
  }
  try {
    const [ivHex, encryptedText] = encryptedData.split(":");
    const iv = CryptoJS.enc.Hex.parse(ivHex);
    const ciphertext = CryptoJS.enc.Hex.parse(encryptedText);

    const decrypted = CryptoJS.AES.decrypt(
      { ciphertext },
      CryptoJS.enc.Utf8.parse(SECRET_KEY),
      {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    );
    const decryptedString = decrypted.toString(CryptoJS.enc.Utf8);
    if (!decryptedString)
      throw new Error("Decryption resulted in empty string");
    return JSON.parse(decryptedString);
  } catch (error) {
    console.error("❌ Decryption Error:", error.message);
    return null;
  }
};

//  Only decrypt if `encryptedData` exists
axios.interceptors.response.use(
  (response) => {
    if (response.data?.encryptedData) {
      try {
        response.data = decryptResponse(response.data.encryptedData);
      } catch (error) {
        console.error("Failed to decrypt response:", error.message);
      }
    } else {
    }
    return response;
  },
  (error) => Promise.reject(error)
);
