import axios from "axios";
import {
  CHATBOT_SESSIONS_GET_ALL_REQUEST,
  CHATBOT_SESSIONS_GET_ALL_SUCCESS,
  CHATBOT_SESSIONS_GET_ALL_FAIL,
} from "../Constants/chatbotSessionConstants";
import { BACKEND_URL } from "../../config";

export const getAllChatbotSessions =
  (start, end, clientId) => async (dispatch) => {
    try {
      dispatch({ type: CHATBOT_SESSIONS_GET_ALL_REQUEST });
      const queryParams = new URLSearchParams({
        start,
        end,
        clientId,
      }).toString();
      const { data } = await axios.get(
        `${BACKEND_URL}api/chatbot-sessions?${queryParams}`
      );
      dispatch({ type: CHATBOT_SESSIONS_GET_ALL_SUCCESS, payload: data });
    } catch (error) {
      const errorMessage = error.response
        ? error.response.data.message
        : error.message;
      dispatch({ type: CHATBOT_SESSIONS_GET_ALL_FAIL, payload: errorMessage });
    }
  };
