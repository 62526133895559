// import React, { useEffect, useState } from "react";
// import { Col, Row, Image } from "react-bootstrap";
// import { Pie } from "react-chartjs-2";
// import { useDispatch, useSelector } from "react-redux";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import CellBadge from "../components/CellBadge";
// import {
//   faClock,
//   faMoneyBill1,
//   faCircleCheck,
// } from "@fortawesome/free-solid-svg-icons";
// import { getAllChatbotSessions } from "../redux/Actions/chatbotSessionAction";

// const QrAnalytics = () => {
//   const { loading, error, sessions } = useSelector(
//     (state) => state.chatbotSessions
//   );
//   const [allSessionsByHotel, setAllSessionsByHotel] = useState({});
//   const [sessionsTodayByHotel, setSessionsTodayByHotel] = useState({});
//   const [sessionsYesterdayByHotel, setSessionsYesterdayByHotel] = useState({});
//   const [hotelNamesMap, setHotelNamesMap] = useState({});

//   const dispatch = useDispatch();

//   useEffect(() => {
//     dispatch(getAllChatbotSessions());
//   }, []);

//   useEffect(() => {
//     if (sessions) {
//       const hotelMap = sessions.reduce((acc, session) => {
//         acc[session.hotelId] = session.clientName;
//         return acc;
//       }, {});
//       setHotelNamesMap(hotelMap);

//       const today = new Date();
//       const startOfDay = new Date(today.setHours(0, 0, 0, 0)).getTime();
//       const startOfYesterday = new Date(
//         today.setDate(today.getDate() - 1)
//       ).getTime();
//       const endOfYesterday = new Date(
//         today.setHours(23, 59, 59, 999)
//       ).getTime();

//       // Group all sessions by hotelId
//       const hotelData = sessions.reduce((acc, session) => {
//         const hotelId = session.hotelId;
//         acc[hotelId] = (acc[hotelId] || 0) + 1;
//         return acc;
//       }, {});

//       setAllSessionsByHotel(
//         Object.keys(hotelData).length ? hotelData : { "No Data": 1 }
//       );

//       // Group today's sessions by hotelId
//       const todayData = sessions.reduce((acc, session) => {
//         const sessionTime = new Date(session.createdAt).getTime();
//         if (sessionTime >= startOfDay) {
//           const hotelId = session.hotelId;
//           acc[hotelId] = (acc[hotelId] || 0) + 1;
//         }
//         return acc;
//       }, {});

//       setSessionsTodayByHotel(
//         Object.keys(todayData).length ? todayData : { "No Data": 1 }
//       );

//       // Group yesterday's sessions by hotelId
//       const yesterdayData = sessions.reduce((acc, session) => {
//         const sessionTime = new Date(session.createdAt).getTime();
//         if (sessionTime >= startOfYesterday && sessionTime <= endOfYesterday) {
//           const hotelId = session.hotelId;
//           acc[hotelId] = (acc[hotelId] || 0) + 1;
//         }
//         return acc;
//       }, {});

//       setSessionsYesterdayByHotel(
//         Object.keys(yesterdayData).length ? yesterdayData : { "No Data": 1 }
//       );
//     }
//   }, [sessions]);

//   const getLabelsFromIds = (ids) =>
//     ids.map((id) => hotelNamesMap[id] || "Unknown Hotel");

//   const pieDataAllSessions = {
//     labels: getLabelsFromIds(Object.keys(allSessionsByHotel)),
//     datasets: [
//       {
//         data: Object.values(allSessionsByHotel),
//         backgroundColor: Object.keys(allSessionsByHotel).length
//           ? ["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0"]
//           : ["#E0E0E0"],
//       },
//     ],
//   };

//   const pieDataToday = {
//     labels: getLabelsFromIds(Object.keys(sessionsTodayByHotel)),
//     datasets: [
//       {
//         data: Object.values(sessionsTodayByHotel),
//         backgroundColor: Object.keys(sessionsTodayByHotel).length
//           ? ["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0"]
//           : ["#E0E0E0"],
//       },
//     ],
//   };

//   const pieDataYesterday = {
//     labels: getLabelsFromIds(Object.keys(sessionsYesterdayByHotel)),
//     datasets: [
//       {
//         data: Object.values(sessionsYesterdayByHotel),
//         backgroundColor: Object.keys(sessionsYesterdayByHotel).length
//           ? ["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0"]
//           : ["#E0E0E0"],
//       },
//     ],
//   };

//   const pieOptions = {
//     maintainAspectRatio: false,
//     responsive: true,
//     plugins: {
//       legend: {
//         display: false,
//       },
//     },
//   };

//   const calculateTotalSessions = (data) =>
//     Object.values(data).reduce((acc, value) => acc + value, 0);

//   const groupedSessions = sessions.reduce((acc, session) => {
//     const url = session.qrCode;
//     if (!acc[url]) acc[url] = [];
//     acc[url].push(session);
//     return acc;
//   }, {});

//   const getServiceLabel = (service) => {
//     switch (service) {
//       case "maid":
//         return "Attendants";
//       case "valet":
//         return "Valet";
//       case "bellman":
//         return "Bell Desk";
//     }
//   };
//   return (
//     <Row className="m-0 p-4 mt-4">
//       {/* {console.log("All hotels sessions:", allSessionsByHotel)}
//       {console.log("Grouped sessions:", groupedSessions)} */}
//       <Col>
//         <Row>
//           <Col className="d-flex flex-column align-items-center">
//             <h6 className="text-center">All Sessions</h6>
//             <p className="text-center">
//               Total: {calculateTotalSessions(allSessionsByHotel)}
//             </p>
//             <div style={{ width: "200px", height: "200px" }}>
//               <Pie data={pieDataAllSessions} options={pieOptions} />
//             </div>
//           </Col>
//           <Col className="d-flex flex-column align-items-center">
//             <h6 className="text-center">Sessions Today</h6>
//             <p className="text-center">
//               Total: {calculateTotalSessions(sessionsTodayByHotel)}
//             </p>
//             <div style={{ width: "200px", height: "200px" }}>
//               <Pie data={pieDataToday} options={pieOptions} />
//             </div>
//           </Col>
//           <Col className="d-flex flex-column align-items-center">
//             <h6 className="text-center">Sessions Yesterday</h6>
//             <p className="text-center">
//               Total: {calculateTotalSessions(sessionsYesterdayByHotel)}
//             </p>
//             <div style={{ width: "200px", height: "200px" }}>
//               <Pie data={pieDataYesterday} options={pieOptions} />
//             </div>
//           </Col>
//         </Row>
//         <Row className="mt-5">
//           {Object.keys(groupedSessions).map((url) => (
//             <Row
//               key={url}
//               className="align-items-center border border-1 p-2 my-2"
//             >
//               <Col xs="auto">
//                 <Image
//                   style={{ width: "120px", height: "120px" }}
//                   fluid
//                   src={url}
//                 />
//               </Col>
//               <Col>
//                 <h5>{groupedSessions[url][0]?.clientName}</h5>
//                 <p className="fs-7">{groupedSessions[url][0]?.location}</p>
//               </Col>
//               <Col className="text-center">
//                 <CellBadge
//                   label={getServiceLabel(groupedSessions[url][0]?.service)}
//                 />
//               </Col>
//               <Col className="text-center">
//                 Total Sessions
//                 <h6 className="text-primary">
//                   <FontAwesomeIcon icon={faClock} />{" "}
//                   {groupedSessions[url].length}
//                 </h6>
//               </Col>
//               <Col className="text-center">
//                 Entered Tip
//                 <h6 className="text-primary">
//                   <FontAwesomeIcon icon={faMoneyBill1} />{" "}
//                   {
//                     groupedSessions[url].filter((s) => s.stage?.tipSelected)
//                       .length
//                   }
//                 </h6>
//               </Col>
//               <Col className="text-center">
//                 Successfully Paid
//                 <h6 className="text-success">
//                   <FontAwesomeIcon icon={faCircleCheck} />{" "}
//                   {
//                     groupedSessions[url].filter((s) => s.stage?.paymentProcesed)
//                       .length
//                   }
//                 </h6>
//               </Col>
//             </Row>
//           ))}
//         </Row>
//       </Col>
//     </Row>
//   );
// };

// export default QrAnalytics;

// import React, { useEffect, useState } from "react";
// import { Col, Row, Image } from "react-bootstrap";
// import { Pie } from "react-chartjs-2";
// import { useDispatch, useSelector } from "react-redux";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import CellBadge from "../components/CellBadge";
// import {
//   faClock,
//   faMoneyBill1,
//   faCircleCheck,
// } from "@fortawesome/free-solid-svg-icons";
// import { getAllChatbotSessions } from "../redux/Actions/chatbotSessionAction";

// const QrAnalytics = () => {
//   const { loading, error, sessions, groupedSessions } = useSelector(
//     (state) => state.chatbotSessions
//   );
//   const [hotelNamesMap, setHotelNamesMap] = useState({});

//   const dispatch = useDispatch();

//   useEffect(() => {
//     dispatch(getAllChatbotSessions());
//   }, []);

//   useEffect(() => {
//     if (sessions) {
//       const hotelMap = sessions.reduce((acc, session) => {
//         acc[session.hotelId] = session.clientName;
//         return acc;
//       }, {});
//       setHotelNamesMap(hotelMap);
//     }
//   }, [sessions]);

//   const getLabelsFromIds = (ids) =>
//     ids.map((id) => hotelNamesMap[id] || "Unknown Hotel");

//   const calculateTotalSessions = (data) =>
//     Object.values(data).reduce((acc, value) => acc + value, 0);

//   const pieDataAllSessions = {
//     labels: getLabelsFromIds(Object.keys(groupedSessions || {})),
//     datasets: [
//       {
//         data: Object.values(groupedSessions || {}).map(
//           (sessions) => sessions.length
//         ),
//         backgroundColor: Object.keys(groupedSessions || {}).length
//           ? ["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0"]
//           : ["#E0E0E0"],
//       },
//     ],
//   };

//   const pieOptions = {
//     maintainAspectRatio: false,
//     responsive: true,
//     plugins: {
//       legend: {
//         display: false,
//       },
//     },
//   };

//   const getServiceLabel = (service) => {
//     switch (service) {
//       case "maid":
//         return "Attendants";
//       case "valet":
//         return "Valet";
//       case "bellman":
//         return "Bell Desk";
//       default:
//         return "Unknown Service";
//     }
//   };

//   return (
//     <Row className="m-0 p-4 mt-4">
//       {console.log("Grouped sessions:", groupedSessions)}
//       <Col>
//         <Row>
//           <Col className="d-flex flex-column align-items-center">
//             <h6 className="text-center">All Sessions</h6>
//             <p className="text-center">
//               Total: {calculateTotalSessions(sessions || {})}
//             </p>
//             <div style={{ width: "200px", height: "200px" }}>
//               <Pie data={pieDataAllSessions} options={pieOptions} />
//             </div>
//           </Col>
//         </Row>
//         <Row className="mt-5">
//           {groupedSessions &&
//             groupedSessions.sessions?.map((session, index) => (
//               <Row
//                 key={index}
//                 className="align-items-center border border-1 p-2 my-2"
//               >
//                 <Col xs="auto">
//                   <Image
//                     style={{ width: "120px", height: "120px" }}
//                     fluid
//                     src={session[0]?.qrCode}
//                   />
//                 </Col>
//                 <Col>
//                   <h5>{session[0]?.clientName}</h5>
//                   <p className="fs-7">{session[0]?.location}</p>
//                 </Col>
//                 <Col className="text-center">
//                   <CellBadge label={getServiceLabel(session[0]?.service)} />
//                 </Col>
//                 <Col className="text-center">
//                   Total Sessions
//                   <h6 className="text-primary">
//                     <FontAwesomeIcon icon={faClock} /> {session.length}
//                   </h6>
//                 </Col>
//                 <Col className="text-center">
//                   Entered Tip
//                   <h6 className="text-primary">
//                     <FontAwesomeIcon icon={faMoneyBill1} />
//                     {session.filter((s) => s.stage?.tipSelected).length}
//                   </h6>
//                 </Col>
//                 <Col className="text-center">
//                   Successfully Paid
//                   <h6 className="text-success">
//                     <FontAwesomeIcon icon={faCircleCheck} />
//                     {session.filter((s) => s.stage?.paymentProcesed).length}
//                   </h6>
//                 </Col>
//               </Row>
//             ))}
//         </Row>
//       </Col>
//     </Row>
//   );
// };

// export default QrAnalytics;

import React, { useEffect, useState } from "react";
import { Col, Row, Image, FormControl, Dropdown } from "react-bootstrap";
import { Pie } from "react-chartjs-2";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CellBadge from "../components/CellBadge";
import { DatePicker, Select } from "antd";
import {
  faClock,
  faMoneyBill1,
  faCircleCheck,
  faMagnifyingGlass,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import { getAllChatbotSessions } from "../redux/Actions/chatbotSessionAction";
import { Atom } from "react-loading-indicators";

const QrAnalytics = () => {
  const { RangePicker } = DatePicker;
  const [dateRange, setDateRange] = useState([null, null]);
  const [query, setQuery] = useState("");
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [allSessionsByHotel, setAllSessionsByHotel] = useState({});
  const [sessionsTodayByHotel, setSessionsTodayByHotel] = useState({});
  const [sessionsYesterdayByHotel, setSessionsYesterdayByHotel] = useState({});
  const [hotelNamesMap, setHotelNamesMap] = useState({});

  const { loading, error, sessions, groupedSessions } = useSelector(
    (state) => state.chatbotSessions
  );
  const {
    loading: usersLoading,
    error: usersError,
    users,
  } = useSelector((state) => state.userList);
  const clients =
    users && users.length > 0
      ? users.filter((user) => !user.isSuperAdmin && !user.isPartner)
      : [];

  const dispatch = useDispatch();

  const handleDateRangeChange = (dates) => {
    if (dates === null || dates[0] === null || dates[1] === null) {
      setErrorMessage(false);
      setDateRange([null, null]);
    } else {
      setDateRange(dates);
    }
  };

  const handleInputChange = (e) => {
    const input = e.target.value;
    setSelectedUserId(null);
    setQuery(input);
    setErrorMessage(false);

    if (input.trim().length > 0) {
      const matches = clients.filter((user) =>
        user.companyName.toLowerCase().includes(input.toLowerCase())
      );
      setFilteredUsers(matches);
      setShowDropdown(matches.length > 0);
    } else {
      setFilteredUsers([]);
      setShowDropdown(false);
    }
  };
  const handleSelectUser = (user) => {
    setQuery(user.companyName);
    setSelectedUserId(user._id);
    setShowDropdown(false);
  };

  useEffect(() => {
    const [start, end] = dateRange;
    let startDate;
    let endDate;
    if (start && end) {
      startDate = new Date(start);
      endDate = new Date(end);
    }
    dispatch(getAllChatbotSessions(startDate, endDate, selectedUserId));
  }, [dispatch, dateRange, selectedUserId]);

  useEffect(() => {
    if (sessions) {
      const hotelMap = sessions.reduce((acc, session) => {
        acc[session.hotelId] = session.clientName;
        return acc;
      }, {});
      setHotelNamesMap(hotelMap);

      const today = new Date();
      const startOfDay = new Date(today.setHours(0, 0, 0, 0)).getTime();
      const startOfYesterday = new Date(
        today.setDate(today.getDate() - 1)
      ).getTime();
      const endOfYesterday = new Date(
        today.setHours(23, 59, 59, 999)
      ).getTime();

      // Group all sessions by hotelId
      const hotelData = sessions.reduce((acc, session) => {
        const hotelId = session.hotelId;
        acc[hotelId] = (acc[hotelId] || 0) + 1;
        return acc;
      }, {});

      setAllSessionsByHotel(
        Object.keys(hotelData).length ? hotelData : { "No Data": 1 }
      );

      // Group today's sessions by hotelId
      const todayData = sessions.reduce((acc, session) => {
        const sessionTime = new Date(session.createdAt).getTime();
        if (sessionTime >= startOfDay) {
          const hotelId = session.hotelId;
          acc[hotelId] = (acc[hotelId] || 0) + 1;
        }
        return acc;
      }, {});

      setSessionsTodayByHotel(
        Object.keys(todayData).length ? todayData : { "No Data": 1 }
      );

      // Group yesterday's sessions by hotelId
      const yesterdayData = sessions.reduce((acc, session) => {
        const sessionTime = new Date(session.createdAt).getTime();
        if (sessionTime >= startOfYesterday && sessionTime <= endOfYesterday) {
          const hotelId = session.hotelId;
          acc[hotelId] = (acc[hotelId] || 0) + 1;
        }
        return acc;
      }, {});

      setSessionsYesterdayByHotel(
        Object.keys(yesterdayData).length ? yesterdayData : { "No Data": 1 }
      );
    }
  }, [sessions]);

  const getLabelsFromIds = (ids) =>
    ids.map((id) => hotelNamesMap[id] || "Unknown Hotel");

  const pieDataAllSessions = {
    labels: getLabelsFromIds(Object.keys(allSessionsByHotel)),
    datasets: [
      {
        data: Object.values(allSessionsByHotel),
        backgroundColor: Object.keys(allSessionsByHotel).length
          ? ["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0"]
          : ["#E0E0E0"],
      },
    ],
  };

  const pieDataToday = {
    labels: getLabelsFromIds(Object.keys(sessionsTodayByHotel)),
    datasets: [
      {
        data: Object.values(sessionsTodayByHotel),
        backgroundColor: Object.keys(sessionsTodayByHotel).length
          ? ["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0"]
          : ["#E0E0E0"],
      },
    ],
  };

  const pieDataYesterday = {
    labels: getLabelsFromIds(Object.keys(sessionsYesterdayByHotel)),
    datasets: [
      {
        data: Object.values(sessionsYesterdayByHotel),
        backgroundColor: Object.keys(sessionsYesterdayByHotel).length
          ? ["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0"]
          : ["#E0E0E0"],
      },
    ],
  };

  const pieOptions = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const calculateTotalSessions = (data) =>
    Object.values(data).reduce((acc, value) => acc + value, 0);

  // const groupedSessions = sessions.reduce((acc, session) => {
  //   const url = session.qrCode;
  //   if (!acc[url]) acc[url] = [];
  //   acc[url].push(session);
  //   return acc;
  // }, {});

  const getServiceLabel = (service) => {
    switch (service) {
      case "maid":
        return "Attendants";
      case "valet":
        return "Valet";
      case "bellman":
        return "Bell Desk";
    }
  };
  return (
    <Row className="m-0 py-4">
      {console.log("Grouped sessions:", groupedSessions)}
      <Col>
        {/* ------------------------------------------------ FILTERS ---------------------------------------------- */}
        <Row className="align-items-center justify-content-center mb-5 p-2 rounded-2 bg-light">
          <Col xs="auto" className="">
            <h3 className="mb-0 text-primary fs-5 ">QR Scans Overview</h3>
          </Col>
          <Col xs={3} className="ms-auto">
            <RangePicker
              className="rounded-2 py-1 border-0 w-100"
              onChange={handleDateRangeChange}
            />
          </Col>
          <Col xs={2}>
            <div className="position-relative">
              <FormControl
                className="rounded-2 py-1 border-0 text-muted"
                type="text"
                value={query}
                onChange={handleInputChange}
                placeholder="Client name"
              />
              <span
                className="position-absolute top-50 end-0 translate-middle-y me-2 text-secondary"
                style={{ cursor: "pointer" }}
              >
                {query || showDropdown ? (
                  <FontAwesomeIcon
                    icon={faCircleXmark}
                    size="sm"
                    onClick={() => {
                      setQuery("");
                      setSelectedUserId(null);
                      setShowDropdown(false);
                      // setFilterApplied(false);
                    }}
                  />
                ) : (
                  <FontAwesomeIcon icon={faMagnifyingGlass} size="sm" />
                )}
              </span>
            </div>
            {showDropdown && (
              <Dropdown show>
                <Dropdown.Menu
                  className="rounded-4 mt-1"
                  style={{ width: "100%" }}
                >
                  {filteredUsers.map((user, index) => (
                    <Dropdown.Item
                      key={index}
                      onClick={() => handleSelectUser(user)}
                    >
                      {user.companyName}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            )}
          </Col>
        </Row>

        {loading ? (
          <Row className="justify-content-center align-items-center vh-100">
            <Col xs="auto">
              <Atom color="#004172" size="medium" text="" textColor="" />
            </Col>
          </Row>
        ) : (
          <>
            {/* --------------------------------------------- PIES ------------------------------------------------ */}
            <Row className="px-5 justify-content-center">
              <Col className="d-flex flex-column align-items-center">
                <h6 className="text-center">All Sessions</h6>
                <p className="text-center">
                  Total: {calculateTotalSessions(allSessionsByHotel)}
                </p>
                <div style={{ width: "200px", height: "200px" }}>
                  <Pie data={pieDataAllSessions} options={pieOptions} />
                </div>
              </Col>
              {!(dateRange[0] && dateRange[1]) && (
                <>
                  <Col className="d-flex flex-column align-items-center">
                    <h6 className="text-center">Sessions Today</h6>
                    <p className="text-center">
                      Total: {calculateTotalSessions(sessionsTodayByHotel)}
                    </p>
                    <div style={{ width: "200px", height: "200px" }}>
                      <Pie data={pieDataToday} options={pieOptions} />
                    </div>
                  </Col>
                  <Col className="d-flex flex-column align-items-center">
                    <h6 className="text-center">Sessions Yesterday</h6>
                    <p className="text-center">
                      Total: {calculateTotalSessions(sessionsYesterdayByHotel)}
                    </p>
                    <div style={{ width: "200px", height: "200px" }}>
                      <Pie data={pieDataYesterday} options={pieOptions} />
                    </div>
                  </Col>
                </>
              )}
            </Row>
            {/* -------------------------------------------- QR CODES --------------------------------------------- */}
            <Row className="mt-5 px-4">
              {groupedSessions &&
                groupedSessions.map((group, index) => (
                  <Row
                    key={index}
                    className="align-items-center border border-1 p-2 my-2"
                  >
                    <Col xs="auto">
                      <Image
                        style={{ width: "120px", height: "120px" }}
                        fluid
                        src={
                          group.sessions[0].qrCode
                            ? group.sessions[0].qrCode
                            : "https://via.placeholder.com/120"
                        }
                      />
                    </Col>
                    <Col>
                      <h5>
                        {group.sessions[0]?.clientName || "Unknown Client"}
                      </h5>
                      <p className="fs-7">{group.sessions[0]?.location}</p>
                    </Col>
                    <Col className="text-center">
                      <CellBadge
                        label={getServiceLabel(group.sessions[0]?.service)}
                      />
                    </Col>
                    <Col className="text-center">
                      Total Sessions
                      <h6 className="text-primary">
                        <FontAwesomeIcon icon={faClock} />{" "}
                        {group.sessions.length}
                      </h6>
                    </Col>
                    <Col className="text-center">
                      Entered Tip
                      <h6 className="text-primary">
                        <FontAwesomeIcon icon={faMoneyBill1} /> {""}
                        {
                          group.sessions.filter((s) => s.stage?.tipSelected)
                            .length
                        }
                      </h6>
                    </Col>
                    <Col className="text-center">
                      Successfully Paid
                      <h6 className="text-success">
                        <FontAwesomeIcon icon={faCircleCheck} />{" "}
                        {
                          group.sessions.filter((s) => s.stage?.paymentProcesed)
                            .length
                        }
                      </h6>
                    </Col>
                  </Row>
                ))}
            </Row>
          </>
        )}
      </Col>
    </Row>
  );
};

export default QrAnalytics;
