import { Route, Routes } from "react-router-dom";
import "./bootstrap.min.css";
import Login from "./screens/Login";
import { Container } from "react-bootstrap";
import Dashboard from "./screens/Dashboard";
import StripeContainer from "./auth/StripeContainer.jsx";
import SuperAdmin from "./screens/SuperAdmin.jsx";
import RequireAuth from "./auth/RequireAuth.jsx";
import RequireAdminAuth from "./auth/RequireAdminAuth.jsx";
import TermsConditions from "./components/Terms&Conditions.jsx";
import PrivacyPolicy from "./components/PrivacyPolicy.jsx";
import Rating from "./components/Rating.jsx";
import ReportDownload from "./components/ReportDownload.jsx";
import TeamTip from "./screens/TeamTip.jsx";
import Terms from "./screens/Terms.jsx";
import ReceiptDownloadPage from "./components/ReceiptDownloadPage.jsx";
import ResetPassword from "./components/ResetPassword.jsx";
import AdminX from "./screens/Admin X/AdminX.jsx";
import StagingProtection from "./auth/StaggingProtection.jsx";

import "../src/auth/responseDecryption.js";

function App() {
  return (
    <>
      <main>
        <Container fluid className="vh-100 p-0 m-0 ">
          <Routes>
            {/* --------------------------------- STAGGING PROTECTION ------------------------------------- */}
            <Route
              path="/login"
              element={
                <StagingProtection>
                  <Login />
                </StagingProtection>
              }
            />
            <Route
              path="/"
              element={
                <StagingProtection>
                  <Login />
                </StagingProtection>
              }
            />
            {/* ------------------------------------------------------------------------------------------- */}
            {/* <Route path="/" element={<Login />} />
            <Route path="/login" element={<Login />} /> */}
            <Route path="/download" element={<ReportDownload />} />
            <Route path="/receipt" element={<ReceiptDownloadPage />} />
            <Route path="/teamtip" element={<TeamTip />} />
            <Route path="/rating" element={<Rating />} />
            <Route path="/tipmaidTerms" element={<TermsConditions />}></Route>
            <Route path="/terms" element={<Terms />} />
            <Route path="/privacyPolicy" element={<PrivacyPolicy />}></Route>
            <Route
              path="/super-admin"
              element={
                <RequireAdminAuth>
                  <SuperAdmin />
                </RequireAdminAuth>
              }
            />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route
              path="/dashboard/adminx"
              element={
                <RequireAuth>
                  <AdminX />
                </RequireAuth>
              }
            />
            <Route
              path="/dashboard"
              element={
                <RequireAuth>
                  <Dashboard />
                </RequireAuth>
              }
            />
            <Route path="/payment" element={<StripeContainer />} />
          </Routes>
        </Container>
      </main>
    </>
  );
}

export default App;
