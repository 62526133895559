import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Col,
  Container,
  Dropdown,
  Form,
  InputGroup,
  ListGroup,
  Nav,
  Navbar,
  Offcanvas,
  Row,
  Tab,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { message } from "antd";
import Logo from "../images/tipkitLogo.svg";
import {
  faBars,
  faChevronDown,
  faClipboard,
  faGear,
  faHouse,
  faPowerOff,
  faUserPlus,
  faArrowLeft,
  faBuilding,
  faUsersGear,
} from "@fortawesome/free-solid-svg-icons";
import Attendants from "./Attendants";
import Reports from "./Reports";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getUserDetails,
  updateUserProfile,
  impersonateUserEnd,
  userLogout,
} from "../redux/Actions/userAction";
import AddRoles from "./AddRoles";
import AddUser from "./AddUser";
import Loader from "../components/Loader";
import Message from "../components/Message";
import Valets from "./Valets";
import Bellman from "./Bellman";
import Documents from "./Documents";
import ClientStats from "./ClientStats";
import ClientReports from "./ClientReports";
import OverlayLoader from "../components/OverlayLoader";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import ClientProfilePage from "./ClientProfilePage";

const Dashboard = () => {
  const [permissions, setPermissions] = useState({
    isAdmin: false,
    canRead: false,
    toDownload: false,
    toAddMaid: false,
    toDeleteMaid: false,
    viewAttendants: false,
    viewValets: false,
    viewBellmans: false,
    adminServiceMaids: false,
    adminServiceValets: false,
    adminServiceBellmen: false,
  });
  const [profile, setProfile] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [activeTab, setActiveTab] = useState("#stats");
  const [collapsed, setCollapsed] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [passwordMatch, setPasswordMatch] = useState(false);
  const [show, setShow] = useState(false);
  const [screenLoading, setScreenLoading] = useState(false);
  const navigate = useNavigate();
  const userInfo = useSelector((state) => state.userDetails);
  const {
    loading: updateLoading,
    success: updateSuccess,
    error: updateError,
  } = useSelector((state) => state.userUpdate);
  const isImpersonating = useSelector(
    (state) => state.userLogin.userInfo.userImpersonating
  );
  const { loading, error, user } = userInfo;

  const dispatch = useDispatch();
  const navigation = useNavigate();

  const handleClose = () => {
    setShow(false);
    setPassword("");
    setConfirmPassword("");
  };

  const handleShow = () => {
    setShow(true);
    setName(user.companyName);
    setEmail(user.email);
  };
  const handleTabChange = (tabKey) => {
    setActiveTab(tabKey);
  };
  const handleSaveChanges = () => {
    if (password == confirmPassword && password != "") {
      setPasswordMatch(false);
      const updatedUser = {
        name,
        email,
        password,
      };
      dispatch(updateUserProfile(updatedUser));
      setShow(false);
      setPassword("");
      setConfirmPassword("");
      // message.success("Password updated");
    } else {
      setPasswordMatch(true);
    }
  };

  const handleLogout = () => {
    dispatch(userLogout());
    navigation("/login");
  };

  const handleBackToSuperAdmin = () => {
    setScreenLoading(true);
    setTimeout(() => {
      setScreenLoading(!screenLoading);
      dispatch(
        impersonateUserEnd(isImpersonating.id, isImpersonating.email, navigate)
      );
    }, 1000);
  };

  useEffect(() => {
    let hideLoading;
    if (updateLoading) {
      hideLoading = message.loading("Updating", 0);
    }
    if (updateSuccess) {
      if (hideLoading) hideLoading();
      message.success("Password updated");
    }
    if (updateError) {
      if (hideLoading) hideLoading();
      message.error(updateError || "Failed to update password");
    }
    return () => {
      if (hideLoading) hideLoading();
    };
  }, [updateLoading, updateSuccess, updateError]);

  useEffect(() => {
    dispatch(getUserDetails());
  }, [dispatch, passwordMatch, updateSuccess]);

  // useEffect(() => {
  //   let activeDashboard;
  //   if (user.isAdmin === false) {
  //     if (user.roles.permissions.viewAttendants)
  //       activeDashboard = "#attendants";
  //     else if (user.roles.permissions.viewValets) activeDashboard = "#valet";
  //     else if (user.roles.permissions.viewBellmans)
  //       activeDashboard = "#bellman";
  //   } else if (user.isAdmin === true) {
  //     if (user.services.maids) activeDashboard = "#attendants";
  //     else if (user.services.valets) activeDashboard = "#valet";
  //     else if (user.services.bellmen) activeDashboard = "#bellman";
  //   }
  //   if (activeDashboard !== activeTab) {
  //     setActiveTab(activeDashboard);
  //   }
  // }, [user]);

  useEffect(() => {
    const updatedPermissions = {
      ...permissions,
      isAdmin: user.isAdmin,
      canRead: user?.roles?.permissions?.read || false,
      toDownload: user?.roles?.permissions?.download || false,
      toAddMaid: user?.roles?.permissions?.addMaid || false,
      toDeleteMaid: user?.roles?.permissions?.deleteMaid || false,
      viewAttendants: user.isAdmin
        ? user.services.maids
        : user.roles?.permissions?.viewAttendants || false,
      viewValets: user.isAdmin
        ? user?.services?.valets
        : user?.roles?.permissions?.viewValets || false,
      viewBellmans: user.isAdmin
        ? user?.services?.bellmen
        : user?.roles?.permissions?.viewBellmans || false,
    };
    setPermissions((prevPermissions) => {
      return JSON.stringify(prevPermissions) !==
        JSON.stringify(updatedPermissions)
        ? updatedPermissions
        : prevPermissions;
    });
    // setPermissions(updatedPermissions);
  }, [user]);

  return (
    <>
      {screenLoading ? <OverlayLoader /> : null}
      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="fw-bold"></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Row className="h-100">
            <Col>
              <h5 className="text-primary fs-4 text-center mt-5">
                Edit Profile
              </h5>
              <hr className="mb-5" />
              {updateError && <Message>{updateError}</Message>}
              {user?.isAdmin && (
                <Row className="align-items-center">
                  <Col md={5}>Company Name</Col>
                  <Col md={7}>
                    <InputGroup className="my-3">
                      <Form.Control
                        type="text"
                        className="border-0 border-bottom"
                        name="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        aria-describedby="basic-addon1"
                      />
                    </InputGroup>
                  </Col>
                </Row>
              )}
              <Row className="align-items-center">
                <Col md={5}>Email</Col>
                <Col md={7}>
                  <InputGroup className="my-3">
                    <Form.Control
                      type="email"
                      className="border-0 border-bottom"
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      aria-describedby="basic-addon1"
                    />
                  </InputGroup>
                </Col>
              </Row>
              <Row className="align-items-center">
                <Col md={5}>New Password</Col>
                <Col md={7}>
                  <InputGroup className="my-3">
                    <Form.Control
                      type={showPassword ? "text" : "password"}
                      className="border-0 border-bottom"
                      name="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      aria-describedby="basic-addon1"
                    />
                    <span
                      className="position-absolute top-50 end-0 translate-middle-y me-2 text-muted"
                      onClick={() => setShowPassword(!showPassword)}
                      style={{ cursor: "pointer" }}
                    >
                      {showPassword ? (
                        <FontAwesomeIcon icon={faEye} />
                      ) : (
                        <FontAwesomeIcon icon={faEyeSlash} />
                      )}
                    </span>
                  </InputGroup>
                </Col>
              </Row>
              <Row className="align-items-center">
                <Col md={5}>Confirm Passowrd</Col>
                <Col md={7}>
                  <InputGroup className="my-3">
                    <Form.Control
                      type={showPassword ? "text" : "password"}
                      className="border-0 border-bottom"
                      name="confirmPassword"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      aria-describedby="basic-addon1"
                    />{" "}
                    <span
                      className="position-absolute top-50 end-0 translate-middle-y me-2 text-muted"
                      onClick={() => setShowPassword(!showPassword)}
                      style={{ cursor: "pointer" }}
                    >
                      {showPassword ? (
                        <FontAwesomeIcon icon={faEye} />
                      ) : (
                        <FontAwesomeIcon icon={faEyeSlash} />
                      )}
                    </span>
                  </InputGroup>
                  {password != confirmPassword && confirmPassword != "" ? (
                    <Form.Text className="text-danger">
                      Passwords does not match
                    </Form.Text>
                  ) : (
                    <></>
                  )}
                </Col>
              </Row>
              <Row className="sticky-bottom mt-5">
                <Col className="align-self-end mx-auto">
                  <Row className="mt-5 justify-content-center">
                    <Col md={6}>
                      <Row className="pe-2">
                        <Button
                          variant="primary"
                          className="d-grid"
                          onClick={handleSaveChanges}
                        >
                          Save Changes
                        </Button>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Offcanvas.Body>
      </Offcanvas>
      {/* --------------------------------------------- NAVBAR -------------------------------------------------- */}
      {isImpersonating && (
        <div className="py-1 bg-success">
          <Container
            fluid
            className="d-flex justify-content-between align-items-center"
          >
            {/* "Logged in as" label */}
            <span className="text-light fs-7">
              <span> {isImpersonating.name}</span> Logged in as:{" "}
              <strong>
                {user && (user.isAdmin ? user.companyName : user.name)}
              </strong>
            </span>
            <Button
              variant="outline-success text-light border-0"
              size="sm"
              onClick={handleBackToSuperAdmin}
            >
              <FontAwesomeIcon icon={faArrowLeft} className="me-2 text-light" />
              Back to {isImpersonating.name || "dashboard"}
            </Button>
          </Container>
        </div>
      )}
      <Navbar bg="primary" variant="dark" className="py-3">
        <Container fluid className="p-0">
          <Row className="justify-content-between align-items-center w-100">
            <Col xs="auto" className={`text-start px-4`}>
              <Button className="fs-5" onClick={() => setCollapsed(!collapsed)}>
                <FontAwesomeIcon icon={faBars} />
              </Button>
            </Col>
            <Col xs="auto" className="text-center">
              <Navbar.Brand>
                <img
                  src={Logo}
                  alt="tipkit"
                  width="100"
                  className="d-inline-block align-top"
                />
              </Navbar.Brand>
            </Col>
            <Col xs="auto">
              <Dropdown align={"end"}>
                <span className=" text-light me-1">
                  {user && user.isAdmin ? user.companyName : user.name}
                </span>
                <Dropdown.Toggle
                  variant="primary"
                  id="dropdown-basic"
                  className="custom-dropdown-toggle"
                >
                  <FontAwesomeIcon icon={faChevronDown} className="fs-6" />
                </Dropdown.Toggle>
                <Dropdown.Menu className="px-2">
                  {/* <Button
                    className="w-100 border-0 mb-2"
                    variant="outline-primary"
                    onClick={handleShow}
                  >
                    <FontAwesomeIcon icon={faGear} className="me-2" /> Settings
                  </Button> */}
                  <Button
                    className="w-100 border-0"
                    variant="outline-danger"
                    onClick={handleLogout}
                  >
                    <FontAwesomeIcon icon={faPowerOff} className="me-2" />{" "}
                    Logout
                  </Button>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </Row>
        </Container>
      </Navbar>
      {/* -------------------------------------------- DASHBOARD TAB -------------------------------------------- */}
      {user && (
        <Tab.Container activeKey={activeTab}>
          <Row className="m-0">
            <Col
              sm={collapsed ? 1 : 2}
              className={`sidebar vh-100 bg-primary sticky-top ${
                collapsed ? "collapsed" : "expanded"
              }`}
            >
              {collapsed ? (
                <Row className="h-100">
                  <Col className="pt-5 mt-5">
                    <Button
                      variant="primary"
                      className="w-100 text-light my-2 fs-5 p-1"
                      onClick={() => setCollapsed(!collapsed)}
                    >
                      <FontAwesomeIcon icon={faHouse} />
                    </Button>
                    <Button
                      variant="primary"
                      className="w-100 text-light my-2 p-1"
                      onClick={() => setCollapsed(!collapsed)}
                    >
                      <FontAwesomeIcon icon={faUsersGear} size="lg" />
                    </Button>
                    <Button
                      variant="primary"
                      className="w-100 text-light my-2 fs-5 p-1"
                      onClick={() => setCollapsed(!collapsed)}
                    >
                      <FontAwesomeIcon icon={faClipboard} />
                    </Button>
                  </Col>
                </Row>
              ) : (
                <Row className="h-100">
                  <Col className="mt-5 pe-3">
                    <h6 className="text-white bg-primary-dark fw-normal mb-0 p-2 rounded-1 fs-7">
                      <FontAwesomeIcon
                        icon={faHouse}
                        className="me-2 "
                        size="sm"
                      />
                      Home
                    </h6>
                    <div
                      className={`button-wrapper ${
                        activeTab === "#stats" ? "active-tab" : ""
                      }`}
                    >
                      <div className="indicator" />
                      <Button
                        variant={
                          activeTab === "#stats" ? "outline-success" : "primary"
                        }
                        className="w-100 text-start border-0 fs-7"
                        onClick={() => handleTabChange("#stats")}
                      >
                        Overview
                      </Button>
                    </div>

                    <div
                      className={`button-wrapper ${
                        activeTab === "#profile" ? "active-tab" : ""
                      }`}
                    >
                      <div className="indicator" />
                      <Button
                        variant={
                          activeTab === "#profile"
                            ? "outline-success"
                            : "primary"
                        }
                        className="w-100 text-start border-0 fs-7"
                        onClick={() => handleTabChange("#profile")}
                      >
                        Profile
                      </Button>
                    </div>
                    <h6 className="text-white bg-primary-dark fw-normal mb-0 mt-2 p-2 rounded-1 fs-7">
                      <FontAwesomeIcon
                        icon={faBuilding}
                        className="me-2"
                        size="sm"
                      />
                      Services
                    </h6>
                    {permissions.viewAttendants && (
                      <div
                        className={`button-wrapper ${
                          activeTab === "#attendants" ? "active-tab" : ""
                        }`}
                      >
                        <div className="indicator" />
                        <Button
                          variant={
                            activeTab === "#attendants"
                              ? "outline-success"
                              : "primary"
                          }
                          className="w-100 text-start border-0 fs-7"
                          onClick={() => handleTabChange("#attendants")}
                        >
                          Attendants
                        </Button>
                      </div>
                    )}
                    {permissions.viewValets && (
                      <div
                        className={`button-wrapper ${
                          activeTab === "#valet" ? "active-tab" : ""
                        }`}
                      >
                        <div className="indicator" />
                        <Button
                          variant={
                            activeTab === "#valet"
                              ? "outline-success"
                              : "primary"
                          }
                          className="w-100 text-start border-0 fs-7"
                          onClick={() => handleTabChange("#valet")}
                        >
                          Valets
                        </Button>
                      </div>
                    )}
                    {permissions.viewBellmans && (
                      <div
                        className={`button-wrapper ${
                          activeTab === "#bellman" ? "active-tab" : ""
                        }`}
                      >
                        <div className="indicator" />
                        <Button
                          variant={
                            activeTab === "#bellman"
                              ? "outline-success"
                              : "primary"
                          }
                          className="w-100 text-start border-0 fs-7"
                          onClick={() => handleTabChange("#bellman")}
                        >
                          Bell Desk
                        </Button>
                      </div>
                    )}

                    {permissions.isAdmin && (
                      <>
                        <h6 className="text-white bg-primary-dark fw-normal p-2 mt-2 mb-0 rounded-1 fs-7">
                          <FontAwesomeIcon
                            icon={faUserPlus}
                            className="me-2"
                            size="sm"
                          />
                          Users
                        </h6>
                        <div
                          className={`button-wrapper ${
                            activeTab === "#roles" ? "active-tab" : ""
                          }`}
                        >
                          <div className="indicator" />
                          <Button
                            variant={
                              activeTab === "#roles"
                                ? "outline-success"
                                : "primary"
                            }
                            className="w-100 text-start border-0 fs-7"
                            onClick={() => handleTabChange("#roles")}
                          >
                            Create Roles
                          </Button>
                        </div>
                        <div
                          className={`button-wrapper ${
                            activeTab === "#adduser" ? "active-tab" : ""
                          }`}
                        >
                          <div className="indicator" />
                          <Button
                            variant={
                              activeTab === "#adduser"
                                ? "outline-success"
                                : "primary"
                            }
                            className="w-100 text-start border-0 fs-7"
                            onClick={() => handleTabChange("#adduser")}
                          >
                            Add User
                          </Button>
                        </div>
                      </>
                    )}
                    <h6 className="text-white bg-primary-dark fw-normal p-2 mt-2 mb-0 rounded-1 fs-7">
                      <FontAwesomeIcon
                        icon={faClipboard}
                        className="me-2"
                        size="sm"
                      />
                      Other
                    </h6>
                    <div
                      className={`button-wrapper ${
                        activeTab === "#reports" ? "active-tab" : ""
                      }`}
                    >
                      <div className="indicator" />
                      <Button
                        variant={
                          activeTab === "#reports"
                            ? "outline-success"
                            : "primary"
                        }
                        className="w-100 text-start border-0 fs-7"
                        onClick={() => handleTabChange("#reports")}
                      >
                        Transactions
                      </Button>
                    </div>
                    <div
                      className={`button-wrapper ${
                        activeTab === "#documents" ? "active-tab" : ""
                      }`}
                    >
                      <div className="indicator" />
                      <Button
                        variant={
                          activeTab === "#documents"
                            ? "outline-success"
                            : "primary"
                        }
                        className="w-100 text-start border-0 fs-7"
                        onClick={() => handleTabChange("#documents")}
                      >
                        Downloads
                      </Button>
                    </div>
                  </Col>
                </Row>
              )}
            </Col>
            <Col sm={collapsed ? 11 : 10} className="p-0 mx-auto">
              <Tab.Content>
                <Tab.Pane eventKey="#attendants">
                  <Attendants
                    toAddMaid={permissions.toAddMaid}
                    toDeleteMaid={permissions.toDeleteMaid}
                    toDownload={permissions.toDownload}
                    isAdmin={permissions.isAdmin}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="#valet">
                  <Valets
                    toAddMaid={permissions.toAddMaid}
                    toDeleteMaid={permissions.toDeleteMaid}
                    toDownload={permissions.toDownload}
                    isAdmin={permissions.isAdmin}
                    canRead={permissions.canRead}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="#bellman">
                  <Bellman
                    toAddMaid={permissions.toAddMaid}
                    toDeleteMaid={permissions.toDeleteMaid}
                    toDownload={permissions.toDownload}
                    isAdmin={permissions.isAdmin}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="#profile">
                  <ClientProfilePage />
                </Tab.Pane>
                <Tab.Pane eventKey="#roles">
                  <AddRoles />
                </Tab.Pane>
                <Tab.Pane eventKey="#adduser">
                  <AddUser />
                </Tab.Pane>
                <Tab.Pane eventKey="#reports">
                  <ClientReports />
                </Tab.Pane>
                <Tab.Pane eventKey="#stats">
                  <ClientStats />
                </Tab.Pane>
                <Tab.Pane eventKey="#documents">
                  <Documents />
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      )}
    </>
  );
};

export default Dashboard;
